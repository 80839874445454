h2 {
    text-align: center;
    color: #1089ff;
    font-weight: 700;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
    0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

h3 {
    text-align: center;
    color: #e5e5e5;
    font-weight: 700;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
    0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

h4 {
    text-align: center;
    color: #e5e5e5;
    font-weight: 500;
}

h5 {
    text-align: center;
    color: #e5e5e5;
    font-weight: 500;
}

#header, #footer {
    position: fixed;
    height: 60px;
    display: block;
    width: 100%;
    background: #23374d;
    z-index: 9;
    text-align: left;
    color: #ffffff;
}

#header {
    top: 0px;
    font-weight: bold;
}

#footer {
    bottom: 0px;
}

#logo {
    float: left;
    margin-left: 10px;
    margin-right: 15px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.pl {
    padding-left: 2em;
}

.pr {
    padding-right: 2em;
}

.center {
    text-align: center;
}

#domain {
    padding-left: 15px;
}

.info {
    clear: both;
    text-align: center;
    margin: 20px;
}

#section2 {
    color: #fff;
    background: url('/assets/images/bg1.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#section3a {
    background: url('/assets/images/bg3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#section4 {
    background: url('/assets/images/bg2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.ant-card-head-title {
    font-weight: bold;
    font-size: 2em;
    color: #e12b38;
}

.firstpage {
    color: #e12b38;
}

.product-card {
    max-width: 320px;
}