body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 3.5em;
  }

  h2 {
    font-size: 3em;
  }

  h3 {
    font-size: 1.8em;
  }

  h4 {
    font-size: 1.2em;
  }

  h5 {
    font-size: 1.1em;
  }

  .standard {
    font-size: 0.9em;
  }

  #header {
    font-size: 1.5em;
  }

  #domain {
    padding-top: 10px;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 7em;
  }

  h2 {
    font-size: 5.5em;
  }

  h3 {
    font-size: 5em;
  }

  h4 {
    font-size: 2em;
  }

  h5 {
    font-size: 1.6em;
  }

  .standard {
    font-size: 1.6em;
  }

  #header {
    font-size: 3em;
  }
}